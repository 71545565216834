import React from "react";
import cover from './cover.jpg';
import author from './author.jpg';
import './App.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight,faArrowCircleLeft, faCodeBranch } from '@fortawesome/pro-duotone-svg-icons'
import { faCopyright, faAd, faUnlock } from '@fortawesome/pro-regular-svg-icons'
import { faTwitter, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons'
import Alert from 'react-bootstrap/Alert'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { Helmet } from 'react-helmet';


const storage_updated = new Event('storage_updated');

class LocalStorageVariable{
  constructor(name) {
    this.name = name;
  }
  
  set(value) {
    window.localStorage[this.name] = value;
    window.dispatchEvent(storage_updated);
  }
  
  get(value) {
    return JSON.parse(window.localStorage.getItem(this.name));
  }
}


class Pets extends React.Component {
  
  constructor(props) {
    super(props);
    this.permissionUpdated = this.permissionUpdated.bind(this);
  }  
  
  permissionUpdated(value) {
    if (value === true) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      document.getElementsByClassName("twitter-tweet")[0].appendChild(script);
    }
  }

  render() {
    
    return <article>
      <StoredValueBanner id="allow_twitter" variant="warning" cta="Allow embeds" heading="This page could be better" callback={this.permissionUpdated}>
        <p>
          The following items are embedded tweets, but to display they must load a script from Twitter. This also allows
          Twitter to gather data about you, so we won't load any data from Twitter without your permission.
          You can change your decision at any time from <LinkContainer to="/privacy/"><a href="/privacy/">the privacy page</a></LinkContainer>.
        </p>
      </StoredValueBanner>
      <h1>Pet reviews</h1>
      <p>Who cares what people have to say about the book? This page pays the cat (and bunny) tax.</p>
      <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Even cats want to up their <a href="https://twitter.com/hashtag/Python?src=hash&amp;ref_src=twsrc%5Etfw">#Python</a> game. Just got <a href="https://twitter.com/matthewwilkes?ref_src=twsrc%5Etfw">@matthewwilkes</a> new book in the mail today. 😎 <a href="https://t.co/P1Gg3qy8tC">pic.twitter.com/P1Gg3qy8tC</a></p>&mdash; Sean Upton (@sdupton) <a href="https://twitter.com/sdupton/status/1291458241331752960?ref_src=twsrc%5Etfw">August 6, 2020</a></blockquote>
      <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Cute animals seem to love this book by the great <a href="https://twitter.com/matthewwilkes?ref_src=twsrc%5Etfw">@matthewwilkes</a>. Looking forward to learn! <a href="https://t.co/rwoJ45ghXm">pic.twitter.com/rwoJ45ghXm</a></p>&mdash; Philip Bauer (@StarzelDe) <a href="https://twitter.com/StarzelDe/status/1292048233498378245?ref_src=twsrc%5Etfw">August 8, 2020</a></blockquote>
      <blockquote className="twitter-tweet" data-conversation="none"><p lang="en" dir="ltr">Lyra wasn’t sure about it at first <a href="https://t.co/blhEGpHaSG">pic.twitter.com/blhEGpHaSG</a></p>&mdash; David Glick (@davisagli) <a href="https://twitter.com/davisagli/status/1292065170005647361?ref_src=twsrc%5Etfw">August 8, 2020</a></blockquote>
    </article>
  }
}

class PageSelect extends React.Component {
  
  constructor(props) {
    super(props);
    this.pageNext = this.pageNext.bind(this);
    this.pagePrev = this.pagePrev.bind(this);
    this.setPageElement = this.setPageElement.bind(this);
    this.state = {
      display_page_number: this.props.number - this.props.offset(this.props.number),
    };
}  

  componentDidUpdate(e) {
    if (this.state.display_page_number !== "" && this.state.display_page_number !== "-") {
      var display_page_number = this.props.number - this.props.offset(this.props.number);
      if (this.state.display_page_number !== display_page_number) {
        this.setState({"display_page_number": display_page_number});
      }
    }
  }
  
  pageNext(e) {
    e.preventDefault();
    this.setPage(this.props.number + 1);
  }
  
  pagePrev(e) {
    e.preventDefault();
    this.setPage(this.props.number - 1);
  }
  
  setPage(n) {
    this.props.setPage(n);
    //console.log("Finding offset for " + n + " to set display");
    this.setState({"display_page_number": n - this.props.offset(n)});
  }
  
  setPageElement(e) {
    e.preventDefault();
    if (e.target.value === "" || e.target.value === "-") {
      // user is typing
      this.setState({"display_page_number": e.target.value});
      //console.log(this.state);
      return;
    }
    else if (e.target.value < this.props.total_pages) {
      let target = parseInt(e.target.value)+this.props.offset(e.target.value, true);
      this.setPage(target);
      if (target < 0 || isNaN(target)) {
        this.setPage(0);
      }
    } else {
      this.setPage(this.props.total_pages)
    }
  }
  
  render() {
    var prev_button = (
      <button className="page-navigate d-none d-md-block" id="page-prev" onClick={this.pagePrev} disabled={this.props.number === 0}><FontAwesomeIcon icon={faArrowCircleLeft} size="3x" />
      </button>
    );

    var next_button = (
      <button className="page-navigate d-none d-md-block" id="page-next" onClick={this.pageNext} disabled={this.props.number === this.props.total_pages}><FontAwesomeIcon icon={faArrowCircleRight} size="3x" />
      </button>
    );
    
    
    return (<div className="book-page">
      <div className="page-select-element justify-content-center">
      {prev_button}<img src={process.env.PUBLIC_URL + "/book/" + this.props.isbn +"/page/"+ this.props.number + ".png"} alt={"Page " + this.state.display_page_number }/>{next_button}
      </div>
      <div className="page-select-element">
        <div className="page-select-input">
          <Button
              className="page-navigate  d-inline d-md-none" onClick={this.pagePrev} disabled={this.props.number === 0}><FontAwesomeIcon icon={faArrowCircleLeft} size="1x" />
          </Button>

          <input type="number" min={- this.props.offset(0, true) } max={this.props.total_pages+1} value={this.state.display_page_number} onChange={this.setPageElement} /> of { this.props.total_pages - this.props.offset(this.props.total_pages) }
          <Button
              className="page-navigate d-inline d-md-none" onClick={this.pageNext} disabled={this.props.number === this.props.total_pages}><FontAwesomeIcon icon={faArrowCircleRight} size="1x" />
          </Button>
        </div>
      </div>
    </div>);
  }
}

class PageMetadata extends React.Component {
  render() {
    if (this.props.github !== undefined ) {
      var code = <><h2>Code</h2>
      <ul>
        { this.props.github.map((value, index) => {
          return <li><FontAwesomeIcon icon={faCodeBranch} /> <a href={"https://github.com/matthewwilkes/" + value.repo + "/tree/" + value.branch }>{value.label ? value.label : value.repo + "at " + value.branch}</a></li>
        })}
      </ul></>;
    }
    
    if (this.props.links !== undefined ) {
      var links = <><h2>Links</h2>
      <ul>
        { this.props.links.map((value, index) => {
          return <li><a href={ value.url }>{value.title }</a></li>
        })}
      </ul></>;
    }
    
    return (
      <article className="page-metadata">
        <div dangerouslySetInnerHTML={ {"__html":this.props.text} } />
        { links }
        { code }
      </article>
    );
  }
}

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.nav = React.createRef();
    this.highlightHome = this.highlightHome.bind(this);
  }
  
  highlightHome(event) {
    // TODO: Fix stale highlight in navbar
  }

  render() {
    return <Navbar ref={this.nav} collapseOnSelect expand="md" bg="dark" variant="dark" className="d-flex mr-auto">
        <LinkContainer to="/#"><Navbar.Brand onClick={this.highlightHome} href="/">
        <img
              alt="Cover of 'Advanced Python Development'"
              src={cover}
              width="56"
              height="80"
              className="d-inline-block align-top"
            />
        </Navbar.Brand></LinkContainer>
      <Navbar.Toggle className="order-3 order-md-1" aria-controls="responsive-navbar-nav" />

      <Form inline className="order-2 order-md-3 justify-content-end justify-content-lg-end">
        <Dropdown alignRight>
          <Dropdown.Toggle variant="outline-primary">Buy</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="https://www.apress.com/us/book/9781484257920"><FontAwesomeIcon icon={faUnlock} title="DRM-free ebook available"/> Apress</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="https://amzn.to/38poX0B"><FontAwesomeIcon icon={faAd} title="Paid promotion"/> Amazon UK</Dropdown.Item>
            <Dropdown.Item href="https://www.waterstones.com/book/advanced-python-development/matthew-wilkes/9781484257920">Waterstones UK</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="https://amzn.to/2ArqtCZ"><FontAwesomeIcon icon={faAd} title="Paid promotion"/> Amazon US</Dropdown.Item>
            <Dropdown.Item href="https://www.barnesandnoble.com/w/advanced-python-development-matthew-wilkes/1136272058">Barnes and Noble</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="http://amazon.de/Advanced-Python-Development-Real-World-Applications/dp/1484257928">Amazon Germany</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="https://dmkpress.com/catalog/computer/programming/python/978-5-97060-930-9/">ДМК (русская версия)</Dropdown.Item>
            
           </Dropdown.Menu>
           
        </Dropdown>
      </Form>
    

      <Navbar.Collapse id="responsive-navbar-nav" className="order-md-1 order-4">
        <Nav className="mr-auto">
        <LinkContainer to="/#">
          <Nav.Link href="/">Home</Nav.Link>
        </LinkContainer>
        {/*<LinkContainer to="/notes">
          <Nav.Link href="/notes">Page notes</Nav.Link>
        </LinkContainer>*/}
        <LinkContainer to="/articles/">
          <Nav.Link href="/articles/">Articles</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/privacy/">
          <Nav.Link href="/privacy/">Privacy</Nav.Link>
        </LinkContainer>
        </Nav>
      </Navbar.Collapse>


  </Navbar>

  }
}

class Footer extends React.Component {
  render() {
    return <footer className="footer" data-nosnippet>
        <Container>
          <span className="text-muted"><FontAwesomeIcon icon={faCopyright} /> 2020 Matthew Wilkes.</span>
          <p className="disclaimer">advancedpython.dev is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.</p>
          <p className="disclaimer">This site stores information on your computer when asked to. See <LinkContainer to="/privacy/"><a href="/privacy/">the privacy page</a></LinkContainer> for more info.</p>
        </Container>
      </footer>
  }
}

class StoredValueSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowed: null,
      storage: this.props.storage === undefined ? new LocalStorageVariable(this.props.id) : this.props.storage 
    };
    this.toggle = this.toggle.bind(this);
    this.updateFromStorage = this.updateFromStorage.bind(this);
  }
  
  componentDidMount() {
    window.addEventListener(
      "storage_updated",
      this.updateFromStorage,
      false
    );
    window.addEventListener(
      "storage",
      this.updateFromStorage,
      false
    );
    this.updateFromStorage();
  }
  
  updateFromStorage() {
    var allowed = this.state.storage.get()
    this.setState({"allowed": allowed===true});
  }

  toggle() {
    this.state.storage.set(!this.state.allowed);
  }

  render() {
    return <Form inline>
    <Form.Check
      type="switch"
      label={ this.props.title }
      onChange={ this.toggle }
      checked={ this.state.allowed === true }
      id={ this.props.id }
    />
  </Form>
  }
}

class StoredValueBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowed: null,
      storage: this.props.storage === undefined ? new LocalStorageVariable(this.props.id) : this.props.storage 
    };
    this.answer = this.answer.bind(this);
    this.updateFromStorage = this.updateFromStorage.bind(this);
  }
  
  componentDidMount() {
    window.addEventListener(
      "storage_updated",
      this.updateFromStorage,
      false
    );
    window.addEventListener(
      "storage",
      this.updateFromStorage,
      false
    );
    this.updateFromStorage();
  }
  
  updateFromStorage() {
    var allowed = this.state.storage.get()
    this.setState({"allowed": allowed});
    this.props.callback(allowed);
  }

  answer(value) {
    this.state.storage.set(value);
  }

  render() {
    var banner = <></>;
    if (this.state.allowed == null) {
      banner = <Alert variant={ this.props.variant } onClose={() => this.answer(false)} dismissible>
        <Alert.Heading>{ this.props.heading }</Alert.Heading>
        { this.props.children }
        <Button onClick={() => this.answer(true)} variant={ this.props.variant }>{ this.props.cta }</Button>
      </Alert>;
    }
    return banner;
  }
}



class LocalStorageContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {"data": null};
    this.clear = this.clear.bind(this);
    this.updateFromStorage = this.updateFromStorage.bind(this);
  }
  
  updateFromStorage() {
    this.setState({"data": window.localStorage});
  }
  
  componentDidMount() {
    window.addEventListener(
      "storage_updated",
      this.updateFromStorage,
      false
    );
    window.addEventListener(
      "storage",
      this.updateFromStorage,
      false
    );
    this.updateFromStorage();
  }
  
  clear() {
    window.localStorage.clear();
    window.dispatchEvent(storage_updated);
  }
  
  render() {
    var state = JSON.stringify(this.state.data, null, 2);
    return <div>
      <pre>{ state }</pre>
      <Button onClick={this.clear} variant="danger">Delete my data</Button>
    </div>
  }
}

class PageNotes extends React.Component {

  
  constructor(props) {
    super(props);
    var current_page = JSON.parse(window.localStorage.getItem("current_page"));
    this.state = {
      isbn: "9781484257920",
      page: current_page ? current_page : 0,
      page_data: {total_pages: 0, pages: [], offset_ranges: {"0": 0}},
      storage: window.localStorage,
    };
    this.setPage = this.setPage.bind(this);
    this.toggle_offline = this.toggle_offline.bind(this);
    this.toggle_remember_page = this.toggle_remember_page.bind(this);
    this.get_offset = this.get_offset.bind(this);
    this.offline = React.createRef();
    this.remember_page = React.createRef();
    
    
    this.remember_position_storage = new LocalStorageVariable("remember_position");
    this.page_number_storage = new LocalStorageVariable("current_page");
  }
  
  setPage(num) {
    this.setState({page: num});
    if (this.remember_position_storage.get()) {
      this.page_number_storage.set(num);
    }
  }

  toggle_offline(evt) {
    evt.preventDefault();
    this.offline.current.toggle();
    return 0;
  }
  
  toggle_remember_page(evt) {
    evt.preventDefault();
    this.remember_page.current.toggle();
    return 0;
  }
  
  componentDidMount() {
    import("./book/" + this.state.isbn + "/data.json")
      .then(data => data.default)
      .then(data => this.setState({ page_data: data }));
  }

  
  get_offset(page, is_display=false) {
    var offset = 0;
    let ranges = this.state.page_data.offset_ranges;
    page = parseInt(page);
    for (var threshold in ranges) {
      if (ranges.hasOwnProperty(threshold)) {
        let offset_val = ranges[threshold];
        threshold = parseInt(threshold);
        //console.log("Aiming for threshold " + threshold);
        if (is_display) {
          if (page + offset_val >= threshold) {
            //console.log("For display page " + page + " found offset " + offset_val + " making file id " + (page+offset_val));
            offset = offset_val;
          } else {
            
          }
        } else {
          if (page >= parseInt(threshold)) {
            offset = parseInt(ranges[threshold]);
            //console.log("For file id " + page + " found offset " + offset_val + " making display id " + (page-offset_val));
            //console.log(this.state.page, offset);
          }
        }
      }
    }
    return offset;
  }
  
  render() { 
    var page_data = this.state.page_data.pages[this.state.page];

    var metadata = <div />;
    if (page_data !== undefined) {
      metadata = <PageMetadata text={page_data.text} links={page_data.links} github={page_data.github} />;
    }
    
    return <article className="page-info p-3">
  
  <div className="d-flex  mr-auto">
  <h1 className="justify-content-start">Page notes</h1>
  <div className="justify-content-end ml-auto">
    <Dropdown alignRight>
      <Dropdown.Toggle variant="secondary">Settings</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={ this.toggle_offline } ><StoredValueSwitch ref={this.offline} id="offline" title="Available Offline" /></Dropdown.Item>
        <Dropdown.Item onClick={ this.toggle_remember_page } ><StoredValueSwitch ref={this.remember_page} storage={this.remember_position_storage} id="remember_position" title="Remember my position" /></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
</div>
  </div>
  <Container fluid>
  <Row noGutters>
    <Col md={8}><PageSelect isbn={this.state.isbn} total_pages={this.state.page_data.total_pages} offsets={this.state.page_data.offset_ranges} offset={this.get_offset} number={this.state.page} setPage={this.setPage} /></Col>
    <Col md={4}>{metadata}</Col>
  </Row>
  </Container>
</article>
;
  }
}

class Home extends React.Component {
  render() {
    return <article className="page-info p-3">
      <Row><Col><h1>Advanced Python Development</h1></Col></Row>
      <Row>
      <Col md={3}><img src={cover} className="big" alt="The book cover" /></Col>
      <Col md={9}>

        <p>This book builds on basic Python tutorials to explain various Python language features that aren’t routinely covered: from reusable console scripts that play double duty as micro-services by leveraging entry points, through to using asyncio efficiently to collate data from a large number of sources. Along the way, it covers type-hint based linting, low-overhead testing and other automated quality checking to demonstrate a robust real-world development process.</p>

    <p>Some powerful aspects of Python are often documented with contrived examples that explain the feature as a standalone example only. By following the design and build of a real-world application example from prototype to production quality the reader will see not only how the various pieces of functionality work but how they integrate as part of the larger
system design process. In addition, it includes the kind of useful asides and library recommendations that are a staple of conference Q&A sessions at Python conferences as well as discussions of modern Python best practice and techniques to better produce clear code that is easily maintainable.</p>

<p>This book is intended for developers who can already write simple programmes in Python to allow them to understand when it’s appropriate to use new and advanced language features and to do so in a confident manner. It is especially of use to developers looking to progress to a more senior level and to very experienced developers who have thus far used older versions of Python.</p>


      </Col>
      
      </Row>

      <Row><Col><h2>About the author</h2></Col></Row>
      <Row>
      <Col md={9}>
        <p>Matthew Wilkes (
          <a className="brandcolor" href="https://twitter.com/matthewwilkes"><FontAwesomeIcon icon={faTwitter} /></a>, <a className="brandcolor" href="https://github.com/matthewwilkes"><FontAwesomeIcon icon={faGithub} /></a>)
           is a European software developer who has worked with Python on web projects for the last fifteen years. As well as developing software, he has long experience in mentoring Python developers in a commercial setting. </p>
      <p>He is also very involved in open source software, with commits to many popular
frameworks. His contributions in that space are focused on the details of database and security interactions of web frameworks.</p>

      </Col>
      <Col md={3}><img src={author} className="big" alt="The author" /></Col>

      </Row>
        
       
        
        
    </article>
  }
}

class Privacy extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      storage: window.localStorage,
    };
    this.offline = React.createRef();
    this.twitter = React.createRef();
    this.page_number = React.createRef();
  }
  
  render() {
    return <article className="page-info p-3">
      <h1>Privacy</h1>
      <p>This website has been built with privacy in mind. I do not collect any information on your use of this website, your IP
        address, or time spent.
      </p>
      <h2>Logging</h2>
      <p>I may, at my discretion, log HTTP accesses to this website. This will be done for the purposes of ensuring
        that the website is functioning correctly. I will not log any personally identifiable information, and such
        logs will not be retained for more than 90 days.</p>
      <h2>Third parties</h2>
      <p>There are no third party analytics installed on this website. No requests are made to third parties to track your
        usage or for any advertising purposes. In particular, there are no Google analytics or Facebook tracking systems installed.
      </p>
      <hr />
      <p>These are some social media embeds in this website. These are disabled by default and are only allowed after you give 
        explicit permission. You can change your recorded permissions here.</p>
        <StoredValueSwitch id="allow_twitter" title="Load twitter embeds" />
      <hr />

      <p>This website includes links to booksellers. These links <em>may</em> be affiliate links, in which case
      I may be informed of any purchases made by following them, as part of an accounting statement. Such links will be marked with <FontAwesomeIcon icon={faAd} title="Paid promotion"/>.
      Any information received
      in this manner will not identify the person who made the purchase, just that a purchase was made. Such information will
      only be used to verify that the booksellers are meeting their obligations under the affiliate agreement and will not be
      published or shared by me.</p>
      
      <h2>"Terminal equipment"</h2>
      <p>Websites may not store cookies (or similar data) on your computer without your permission, unless it's strictly
        necessary for the website to be usable.</p>
        <hr  />
      <p>There is an optional feature to make this website available offline. For this to work data must
        be stored on your computer, so it is disabled by default. If you enable this feature, then the website may cache all the information required for all of its
        functionality to work offline. If it's disabled, then no specific caching policy will be enforced. Your browser
        may still cache things, but this will not be on my specific instructions.
      </p>
      <StoredValueSwitch title="Available offline" id="offline" />
      <hr />
      <p>In addition, the website can remember which page you most recently viewed. This can greatly improve the
        browsing experience, but also needs your explicit permission.</p>
      <StoredValueSwitch title="Remember my position" id="remember_position" />
      <hr />
      <p>Neither of these options cause information about your usage habits to be transmitted outside of your computer.
        In fact, enabling the offline option can very slightly improve your privacy, as it would make
        it impossible for third parties analysing your network connection to monitor your reading.
      </p>

      <h2>Accessing your data</h2>
      <p>You have a right to view all data I store on you, on request. Nothing is stored outside your computer. The
        data I store on your computer is shown below.</p>
      <LocalStorageContents state={window.localStorage} />
      
      
    </article>
  }
}


class ArticleDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }
  
  componentDidMount(data) {
    import("./articles/" + this.props.slug + ".json")
      .then(data => this.setState(data.default))
      .catch((error) => this.setState({found: false}));
  }
  
  render() {
    var body;
    if (this.state.found === false) {
      body = <h1>404 Not Found</h1>;
    } else {
      body = <div dangerouslySetInnerHTML={ {"__html":this.state.body} } />;
    }
    return <article className="page-info p-3">
      <Helmet>
          <title>{ this.state.title  }</title>
          <meta name="description" content={this.state.summary} />
          <meta name="og:title" content={this.state.title} />
          <meta name="og:description" content={this.state.summary} />
          <meta property="og:type" content="article" />
      </Helmet>
      <div className="article-intro">
        <h1>{this.state.title}</h1>
        <cite>{this.state.author} on {this.state.date}</cite>
      </div>
      { body }
    </article>
  }
}

function Article() {
  let { blogSlug } = useParams();
  return <ArticleDisplay slug={blogSlug} />;
}

function TypeLogo(params) {
  if (params.type === 'video') {
    return <FontAwesomeIcon color="red" icon={faYoutube} /> ;
  }
  return "";
}

class ArticleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.render();
  }
  
  componentDidMount(data) {
    import("./articles/article_listing.json")
      .then(data => this.setState({"items":data.default}));
  }
  
  render() {
    return <div className="page-info p-3">
      <h1>Articles</h1>
      <p>In addition to the advice in the book, this section of the website contains some additional helpful hints.
        These will generally be expansions on things in the book with a specific viewpoint in mind. If you find these
        useful and haven't yet taken a look at the book, I'd (humbly) suggest that you do so. If you like these, you'll
        like the book.
      </p>
      <hr />
      <ul className="article-list">
       {this.state.items.map(blog => (
        <li key={blog.slug} >
          <span className="date">{ blog.date }</span>
          <LinkContainer to={"/articles/"+ blog.slug}>
            <a href={"/articles/"+ blog.slug}><TypeLogo type={blog.type} /> {blog.title}</a>
          </LinkContainer>
          <p>{ blog.summary }</p>
        </li>
      ))}
      </ul>
    </div>
  }
}


function storageMock() {
  var storage = {};

  return {
    setItem: function(key, value) {
      storage[key] = value || '';
    },
    getItem: function(key) {
      return key in storage ? storage[key] : null;
    },
    removeItem: function(key) {
      delete storage[key];
    },
    get length() {
      return Object.keys(storage).length;
    },
    key: function(i) {
      var keys = Object.keys(storage);
      return keys[i] || null;
    }
  };
}


class App extends React.Component {

  
  render () {
    if (window.localStorage === undefined) {
      window.localStorage = storageMock();
    }
    return (
      <div className="App">
        <Navigation />
        
        <div className="container">
        <Switch>
          <Route path="/pets">
            <Pets />
          </Route>
          <Route path="/notes">
            <PageNotes />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/articles">
            <ArticleList />
          </Route>
          <Route path={`/articles/:blogSlug`}>
            <Article />
          </Route>
          <Route path="/">
            <Home />
            <Helmet>
            <script type="application/ld+json">{`
            {
              "@context": "http://schema.org",
              "@type": "Book",
              "copyrightHolder": {
                "@type": "Person",
                "name": "Matthew Wilkes"
              },
              "copyrightYear": "2020",
              "inLanguage": "en-US",
              "isFamilyFriendly": "true",
              "isbn": "9781484257920",
              "name": "Advanced Python Development",
              "publisher": {
                "@type": "Organization",
                "name": "Apress"
              }
            }`}
            </script>
            </Helmet>
          </Route>
        </Switch>
        </div>
        
        <Footer />
      </div>
    );
  }
}

export default App;
